/* eslint-disable angular/window-service */
import { getConfigFromEnv } from 'Common/utilities/config';
import OktaAuth from '@okta/okta-auth-js';
window.technology = 'angular';

const config = getConfigFromEnv();

const oktaConfig = {
  issuer: config.oktaIssuer,
  clientId: config.oktaClientId,
  redirectUri: `${window.location.origin}/app/authorization-code/callback`,
  tokenManager: {
    autoRenew: true,
    expireEarlySeconds: Number(config.oktaEarlyRenew),
    storageKey: 'mycrm-tokens', // note: when changing this, also change the value on mycrm-react
  },
};

const auth = new OktaAuth(oktaConfig);
window.auth = auth;
